const isProdBuild = process.env.NODE_ENV === 'production';
const host = window.location.hostname;
const notLocalhost = host !== 'localhost';

const indexOfDash = host.indexOf('-')
const isProdHost = indexOfDash < 0;
const hostWithoutDashPrefix = host.substring(indexOfDash + 1);
const getBaseDomain = () => host.substring(host.indexOf('.') + 1);

export const API_BASE_URL = isProdBuild ?
  (isProdHost ?
    `https://api.${getBaseDomain()}`
    : (notLocalhost ?
      `https://api-${hostWithoutDashPrefix}`
      : process.env.REACT_APP_API_HOST))
  : process.env.REACT_APP_API_HOST;

export const APP_BASE_URL = notLocalhost ? `https://${host}` : process.env.REACT_APP_HOST_URL;

export const DEFAULT_BRANDS_HOST =  isProdBuild ?
  (isProdHost ?
    `https://${getBaseDomain()}`
    : (notLocalhost ?
      `https://${hostWithoutDashPrefix}`
      : process.env.REACT_APP_DEFAULT_BRANDS_HOST))
  : process.env.REACT_APP_DEFAULT_BRANDS_HOST;

export const OAUTH2_REDIRECT_URI = APP_BASE_URL + '/oauth2/redirect';

export const GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;

export const FACEBOOK_AUTH_URL = API_BASE_URL + '/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;

export const UTAHID_AUTH_URL = API_BASE_URL + '/oauth2/authorize/utahid?redirect_uri=' + OAUTH2_REDIRECT_URI;
