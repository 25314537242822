import React from 'react';

import logo from 'images/logo.png';
import styles from './Brand.module.css';

const Brand = () => {
  return (
    <div className={styles.wrapper}>
      <img src={logo} alt="Utah Brands" className={styles.logo} />
      <h1 className={styles.name}>Utah Brands</h1>
    </div>
  );
};

export default Brand;
