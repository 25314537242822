import React from 'react';
import ReactDOM from 'react-dom';
import '@acclaim/udaf-kit/dist/index.css';
import 'index.css';
import App from 'app/index.js';
import { BrowserRouter } from 'react-router-dom';
import { UdafContext } from '@acclaim/udaf-kit';
import { API_BASE_URL, GOOGLE_AUTH_URL, FACEBOOK_AUTH_URL, UTAHID_AUTH_URL } from 'common/constants';

import * as serviceWorker from './serviceWorker';

const OPTIONS = {
  API_BASE_URL,
  GOOGLE_AUTH_URL,
  FACEBOOK_AUTH_URL,
  UTAHID_AUTH_URL
};

ReactDOM.render(
  <React.StrictMode>
    <UdafContext.Provider value={OPTIONS}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </UdafContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
