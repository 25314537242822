import React, { useState } from 'react'
import { UdafSignup, UdafOauth } from '@acclaim/udaf-kit'
import { Link } from 'react-router-dom';

import { redirectToAuthenticatedApp } from 'common/helpers';
import Panel from 'components/panel/Panel';
import Brand from 'components/brand/Brand';
import styles from './Signup.module.css';

const SignupPage = () => {
  const [errorMsg, setErrorMsg] = useState('');

  const handleSuccess = (response) => {
    setErrorMsg('');
    redirectToAuthenticatedApp(response.data.accessToken);
  };

  const handleError = (error) => {
    if (error && error.data) {
      const { data: { message } } = error;
      setErrorMsg(message || 'Oops! Something went wrong. Please try again!');
    } else {
      setErrorMsg('Oops! Something went wrong. Please try again!');
    }
  };

  return (
    <Panel>
      <Brand />
      {errorMsg && <p className="error-msg">{errorMsg}</p>}
      <div className="auth-row">
        <div>
          <div className={styles.action}>
            <small className={styles.label}>Already have an account?</small>
            <Link className="btn" to="/">log in</Link>
          </div>
          <UdafSignup onSuccess={handleSuccess} onError={handleError} />
        </div>
        <div>or</div>
        <div>
          <UdafOauth />
        </div>
      </div>
      <div className={styles.forgotPassword}>
        <Link to="/forgot-password" className="link">forgot password</Link>
      </div>
    </Panel>
  );
};

export default SignupPage;
