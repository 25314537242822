import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import queryString from 'query-string'

import LoginPage from 'pages/login'
import SignupPage from 'pages/signup'
import ForgotPasswordPage from 'pages/forgotPassword'
import OauthRedirection from 'pages/oauthRedirection'
import { setRequestQuery } from 'common/helpers'

const AuthRoute = (props) => {
  const {
    children,
    location,
    ...rest
  } = props

  if (location.search) {
    const query = queryString.parse(location.search)

    setRequestQuery(query)

    return <Redirect to={location.pathname} />
  } else {
    return (
      <Route {...rest}>
        {children}
      </Route>
    )
  }
}

function App() {
  return (
    <>
      <Switch>
        <AuthRoute exact path="/">
          <LoginPage />
        </AuthRoute>
        <AuthRoute exact path="/signup">
          <SignupPage />
        </AuthRoute>
        <AuthRoute exact path="/forgot-password">
          <ForgotPasswordPage />
        </AuthRoute>
        <Route exact path="/oauth2/redirect">
          <OauthRedirection />
        </Route>
      </Switch>
      <span>Version {process.env.REACT_APP_VERSION}</span>
    </>
  )
}

export default App
