import React, { useState } from "react";
import { UdafLogin, UdafOauth } from "@acclaim/udaf-kit";
import { Link, useLocation } from "react-router-dom";

import Panel from "components/panel/Panel";
import Brand from "components/brand/Brand";
import styles from "./Login.module.css";
import {
  getRequestQuery,
  redirectToAuthenticatedApp,
} from "../../common/helpers";

const LoginPage = () => {
  const [monthsData, setmonthsData] = useState(2);

  const location = useLocation();
  const [errorMsg, setErrorMsg] = useState(
    location.state ? location.state.error : ""
  );

  const handlemonthsChange = (event) => {
    setmonthsData(event.target.value);
  };

  const handleSuccess = (response) => {
    setErrorMsg("");
    if (response) {
      redirectToAuthenticatedApp(response.data.accessToken, monthsData);
    } else {
      redirectToAuthenticatedApp("", monthsData);
      sessionStorage.setItem("session_months_data", monthsData);
    }
  };

  const handleError = (error) => {
    console.log(error);
    if (error && error?.data) {
      const message =
        error?.data?.message || "Oops! Something went wrong. Please try again!";

      setErrorMsg(message);
    } else {
      setErrorMsg("Oops! Something went wrong. Please try again!");
    }
  };

  const checkRedirecturi = () => {
    const requestQuery = getRequestQuery();
    const displayMonthcontrol = requestQuery.redirect_uri
      ? requestQuery.redirect_uri.includes("inspect")
      : false;
    return displayMonthcontrol;
  };

  return (
    <Panel>
      <Brand />
      {errorMsg && <p className="error-msg">{errorMsg}</p>}
      <div className="auth-row">
        <div>
          <div className={styles.action}>
            <small className={styles.label}>New User?</small>
            <Link className="btn" to="/signup">
              sign up
            </Link>
          </div>
          <UdafLogin onSuccess={handleSuccess} onError={handleError} />
          {checkRedirecturi() && (
            <div className={styles.monthsdata}>
              <small className={styles.label}>
                How much data to retrieve(in months)?
              </small>
              <input
                style={{ width: "50px" }}
                type="number"
                value={monthsData}
                onChange={handlemonthsChange}
              />
            </div>
          )}
        </div>
        <div>or</div>
        <div>
          <UdafOauth beforeAuth={handleSuccess} />
        </div>
      </div>
      <div className={styles.forgotPassword}>
        <Link to="/forgot-password" className="link">
          forgot password
        </Link>
      </div>
    </Panel>
  );
};

export default LoginPage;
