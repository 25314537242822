import { DEFAULT_BRANDS_HOST } from "./constants";

const TOKEN_KEY = 'accessToken';
const QUERY_PARAMETERS_KEY = 'requestQuery';

export const setToken = (token) => {
  window.localStorage.setItem(TOKEN_KEY, token);
};

export const remoteToken = () => {
  window.localStorage.removeItem(TOKEN_KEY);
};

export const setRequestQuery = (json) => {
  window.sessionStorage.setItem(QUERY_PARAMETERS_KEY, JSON.stringify(json));
};

export const getRequestQuery = () => {
  const session = window.sessionStorage.getItem(QUERY_PARAMETERS_KEY);

  return JSON.parse(session || '{}');
};

export const redirectToAuthenticatedApp = (token, monthsData) => {
  const requestQuery = getRequestQuery();

  window.location.href =
    requestQuery.redirect_uri ?
      `${requestQuery.redirect_uri}?token=${token}&monthsData=${monthsData}`
        :  `${DEFAULT_BRANDS_HOST}/authenticate?token=${token}&monthsData=${monthsData}`;
};

export const removeRequestQuery = () => {
  window.sessionStorage.removeItem(QUERY_PARAMETERS_KEY);
};
