import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { UdafForgotPassword } from '@acclaim/udaf-kit';

import Panel from 'components/panel/Panel';
import Brand from 'components/brand/Brand';
import styles from './ForgotPassword.module.css';

const ForgotPasswordPage = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const [instructions, setInstructions] = useState('');

  const handleSuccess = (response) => {
    setInstructions(response.data.message);
  };

  const handleError = (error) => {
    if (error && error.data) {
      const { data: { message } } = error;
      setErrorMsg(message || 'Oops! Something went wrong. Please try again!');
    } else {
      setErrorMsg('Oops! Something went wrong. Please try again!');
    }
  };

  return (
    <Panel>
      <Brand />
      {errorMsg && <p className="error-msg">{errorMsg}</p>}
      <div className={styles.form}>
        <p className={styles.label}>Please enter your email to request a password reset:</p>
        {instructions ?
          (
            <p className={styles.label}>{instructions}</p>
          ) : (
            <UdafForgotPassword onSuccess={handleSuccess} onError={handleError} />
          )
        }
      </div>
      <div className={styles.backToLogin}>
        <Link className="link" to="/">
          Back to Login
        </Link>
      </div>
    </Panel>
  );
};

export default ForgotPasswordPage;
