import React from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import queryString from 'query-string';

import { redirectToAuthenticatedApp } from 'common/helpers';
import Panel from 'components/panel/Panel';

const OauthRedirection = () => {
  const history = useHistory();
  const query = queryString.parse(history.location.search);
  const monthsData = sessionStorage.getItem("session_months_data");

  if (query.token) {
    redirectToAuthenticatedApp(query.token,monthsData);
    return (
      <Panel>
        <div style={{ textAlign: 'center' }}>
          <p>Authenticating. Please wait...</p>
        </div>
      </Panel>
    )
  } else {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { error: query.error || 'Something went wrong.' }
        }}
      />
    )
  }
};

export default OauthRedirection;
