import React from 'react';
import styles from './Panel.module.css';

const Panel = ({ ...props }) => {
  return (
    <div className={styles.container}>
      <div {...props} className={styles.panel}>
        {props.children}
      </div>
    </div>
  );
};

export default Panel;
